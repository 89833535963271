import { FC, useEffect, useState } from 'react'

import { SxProps, Theme, Box, Typography, debounce } from '@mui/material'
import axios from 'axios'
import { useForm } from 'react-hook-form'

import {
  Icon3DCube,
  IconBuildingFactory,
  IconFilter,
  IconSitemap,
} from 'src/assets/icons'
import { IconWithText } from 'src/components/IconWithText'
import { Search } from 'src/components/Search'
import { ITableColumns, Table } from 'src/components/Table'
import { ITablePaginationProps } from 'src/components/Table/types'
import convertTimestampToLocal from 'src/helpers/timestamp-generator'
import { CustomButton } from 'src/ui/Button'

import { ExtendableLogRow } from './components/ExtendableLogRow'
import { useBeforeUnload } from 'react-router-dom'
import { Select } from 'src/ui/Select'

const columns: ITableColumns[] = [
  {
    accessor: 'name',
    header: 'Bodymaker',
    width: 0,
    isSortable: true,
  },
  {
    accessor: 'factoryName',
    header: 'Plant',
    width: 194,
    isSortable: true,
  },
  {
    accessor: 'lineName',
    header: 'Line',
    width: 194,
    isSortable: true,
  },
  {
    accessor: 'lastUpdated',
    header: 'Time Stamp',
    width: 214,
    isSortable: true,
  },
  {
    accessor: 'connectionStatus',
    header: 'Status',
    width: 124,
    isSortable: true,
  },
]

const handleBackChip = (option: string): string => {
  switch (option) {
    case 'Connected':
      return '#37B24D'
    case 'Disconnected':
      return '#6A7178'
    default:
      return '#C92A2A'
  }
}

const handleStroke = (option: string): SxProps<Theme> => {
  switch (option) {
    case 'Connected':
      return { '& path': { stroke: '#37B24D' } }

    case 'Disconnected':
      return { '& path': { stroke: '#6A7178' } }
    default:
      return { '& path': { stroke: '#C92A2A' } }
  }
}

export const FleetLog: FC = () => {
  const [data, setData] = useState()
  // const form = useForm()
  const [regions, setRegions] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState<ITablePaginationProps>({
    page: 1,
    totalPages: 1,
    limit: 5,
    totalItems: 1,
    searchQuery: '',
    sort: {
      field: '',
      order: 'asc',
    },
    regions: [],
    plants: [],
    lines: [],
    onPageChange: (page, limit) => {
      setPagination((prevState) => ({
        ...prevState,
        page,
        limit,
      }))
    },
  })

  const handleSorting = (column: string, dir: any): void => {
    setPagination((prevState) => {
      return {
        ...prevState,
        page: 1,
        sort: {
          field: column,
          order: dir,
        },
      }
    })
  }
  const filters =
    regions &&
    regions.map((region) => {
      return { value: region, label: region }
    })
  const onFilterChange = (value: string[]): void => {
    setPagination((prevState) => ({
      ...prevState,
      regions: value,
      page: 1,
    }))
  }
  const [plants, setPlants] = useState<
    Array<{
      id: string
      name: string
    }>
  >([])
  const plantFilters =
    plants &&
    plants.map((plant) => {
      return { value: plant.id, label: plant.name }
    })

  const onPlantFilterChange = (value: any[]): void => {
    console.log(value)

    setPagination((prevState) => ({
      ...prevState,
      plants: value,
      page: 1,
    }))
  }

  const [lines, setLines] = useState<
    Array<{
      id: string
      name: string
    }>
  >([])
  const lineFilters =
    lines &&
    lines.map((line) => {
      return { value: line.id, label: line.name }
    })

  const onLineFilterChange = (value: any[]): void => {
    console.log(value)

    setPagination((prevState) => ({
      ...prevState,
      lines: value,
      page: 1,
    }))
  }

  const handleSearchDebounced = debounce((value) => {
    setPagination((prevState) => ({
      ...prevState,
      searchQuery: value,
      searchField: 'name',
      page: 1,
    }))
  }, 500)

  // const handleFormSubmit = form.handleSubmit((values) => {
  //   console.log(values)
  // })

  const handleExportClick = async (): Promise<void> => {
    // try {
    //   const response = await axios.get('/company/export-logs', {
    //     responseType: 'blob',
    //   })
    //   const url = window.URL.createObjectURL(new Blob([response.data]))
    //   const link = document.createElement('a')
    //   link.href = url
    //   link.setAttribute('download', 'machine-logs.xlsx')
    //   document.body.appendChild(link)
    //   link.click()
    //   document.body.removeChild(link)
    // } catch (error) {
    //   console.log(error)
    // }
  }

  // /bodymaker-logs/live-status
  const [bodymakerIds, setBodymakerIds] = useState<string[]>([])

  const [eventSource, setEventSource] = useState<EventSource | null>()

  useBeforeUnload(() => {
    eventSource?.close()
  })

  useEffect(() => {
    const userString = localStorage.getItem('user') ?? ''
    const user = JSON.parse(userString)
    const token = user?.token

    const newEventSource = new EventSource(
      `${
        axios.defaults.baseURL
      }company/bodymaker-logs/live-status/${bodymakerIds.join(
        ',',
      )}?token=${token}`,
      { withCredentials: true },
    )

    setEventSource(newEventSource)

    return () => {
      newEventSource.close()
    }
  }, [bodymakerIds])

  useEffect(() => {
    eventSource?.addEventListener('message', (event) => {
      const newData = JSON.parse(event.data)

      setData((prevState: any) => {
        const updatedData = prevState.map((item: any) => {
          const matchingItem = newData.find(
            (newItem: any) => newItem.id === item.id,
          )

          if (matchingItem) {
            return {
              ...item,
              ...matchingItem,
              name: (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconWithText
                    src={Icon3DCube}
                    text={item.preservedName}
                    stylesContainer={handleStroke(
                      matchingItem.connectionStatus,
                    )}
                    styles={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#272B30',
                    }}
                  />
                </Box>
              ),
              lastUpdated: (
                <Typography
                  variant="body2"
                  sx={{
                    lineHeight: '18px',
                    color: '#4F575E',
                    fontSize: '12px',
                    width: 'max-content',
                  }}
                >
                  {matchingItem.lastUpdated
                    ? convertTimestampToLocal(matchingItem.lastUpdated)
                    : 'Unknown'}
                </Typography>
              ),
              connectionStatus: matchingItem.connectionStatus ? (
                <Box
                  sx={{
                    display: 'inline-block',
                    borderRadius: '100px',
                    lineHeight: '18px',
                    color: '#FFF',
                    fontSize: '12px',
                    fontWeight: 700,
                    p: '3px 8px',
                    bgcolor: handleBackChip(matchingItem.connectionStatus),
                  }}
                >
                  {matchingItem.connectionStatus ?? 'N/A'}
                </Box>
              ) : (
                'N/A'
              ),
            }
          }

          return item
        })

        return updatedData
      })
    })

    return () => {
      eventSource?.close()
    }
  }, [eventSource])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)

      const { data } = await axios.get('/company/bodymaker-logs', {
        params: {
          ...pagination,
          sort: undefined,
          sortColumn: pagination.sort.field,
          sortOrder: pagination.sort.order,
          onPageChange: undefined,
          regions: undefined,
          region: pagination.regions,
          plants: undefined,
          plantIds: pagination.plants,
          lines: undefined,
          lineIds: pagination.lines,
        },
      })
      setData(data.bodymakers)
      console.log(data.bodymakers)

      setBodymakerIds(data.bodymakers.map((bodymaker: any) => bodymaker.id))

      setRegions(data.regions)
      setPlants(data.plants)
      setLines(data.lines)
      setPagination((prevState) => ({
        ...prevState,
        totalPages: data.totalPages,
        totalItems: data.totalItems,
        // page: data.page,
        // searchQuery: data.searchQuery,
        // searchField: data.searchField,
      }))
    }

    fetchData()
      .then(() => {
        setData((prevState: any) => {
          console.log(prevState)

          const temp = prevState.map((item: any) => {
            return {
              ...item,
              preservedName: item.name,
              name: (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconWithText
                    src={Icon3DCube}
                    text={item.name}
                    stylesContainer={handleStroke(item.connectionStatus)}
                    styles={{
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#272B30',
                    }}
                  />
                </Box>
              ),
              factoryName: (
                <IconWithText
                  src={IconBuildingFactory}
                  text={item.line.plant.name}
                  stylesContainer={{
                    '& path': { stroke: 'secondary.main' },
                  }}
                  styles={{
                    color: 'secondary.main',
                  }}
                />
              ),
              lineName: (
                <IconWithText
                  src={IconSitemap}
                  text={item.line.name}
                  stylesContainer={{
                    '& path': { stroke: '#secondary.main' },
                  }}
                  styles={{
                    color: 'secondary.main',
                  }}
                />
              ),
              lastUpdated: (
                <Typography
                  variant="body2"
                  sx={{
                    lineHeight: '18px',
                    color: '#4F575E',
                    fontSize: '12px',
                    width: 'max-content',
                  }}
                >
                  {item.lastUpdated
                    ? convertTimestampToLocal(item.lastUpdated)
                    : 'Unknown'}
                </Typography>
              ),
              connectionStatus: item.connectionStatus ? (
                <Box
                  sx={{
                    display: 'inline-block',
                    borderRadius: '100px',
                    lineHeight: '18px',
                    color: '#FFF',
                    fontSize: '12px',
                    fontWeight: 700,
                    p: '3px 8px',
                    bgcolor: handleBackChip(item.connectionStatus),
                  }}
                >
                  {item.connectionStatus ?? 'N/A'}
                </Box>
              ) : (
                'N/A'
              ),
            }
          })
          return temp
        })
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err.message)
        setIsLoading(false)
      })
  }, [
    pagination.page,
    pagination.limit,
    pagination.searchQuery,
    pagination.sort,
    pagination.regions,
    pagination.plants,
    pagination.lines,
  ])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          // justifyContent: 'space-between',
          gap: '16px',
          mt: '48px',
        }}
      >
        <Search
          searchStyles={{
            width: '364px',
            padding: '0.3rem 3rem 0.3rem 0',
          }}
          selectLabel={{ name: 'Region', id: 'region' }}
          styles={{ width: 'auto' }}
          onChange={(e) => handleSearchDebounced(e.target.value)}
          hasFilters={true}
          selectStyles={{
            backgroundColor: '#F5F7F9',
            borderRadius: '4px',
          }}
          onFilterChange={(e: any) => {
            onFilterChange(e)
          }}
          options={filters}
          isMultiFilter={true}
        />
        <Select
          onChange={onPlantFilterChange}
          options={plantFilters}
          isSmall
          width={200}
          isMultiSelect
          selectLabel={{ name: 'Plant', id: 'plant' }}
          values={plantFilters && plantFilters.map((item) => item.label)}
          styles={{
            backgroundColor: '#F5F7F9',
            borderRadius: '4px',
          }}
        />
        <Select
          onChange={onLineFilterChange}
          options={lineFilters}
          isSmall
          width={200}
          isMultiSelect
          selectLabel={{ name: 'Line', id: 'line' }}
          values={lineFilters && lineFilters.map((item) => item.label)}
          styles={{
            backgroundColor: '#F5F7F9',
            borderRadius: '4px',
          }}
        />
        {/* <Box
        {/* <Box
          sx={{
            display: 'flex',
          }}
        >
          <CustomButton
            appearance="primary-soft-default"
            onClick={handleExportClick}
          >
            Export to Excel
          </CustomButton>
          <CustomButton
            appearance="primary-soft-default"
            icon={IconFilter}
            styles={{ ml: '16px' }}
          >
            Add Device
          </CustomButton>
        </Box> */}
      </Box>
      <Table
        data={data}
        loading={isLoading}
        columns={columns}
        handleSorting={handleSorting}
        pagination={pagination}
        extendableComponent={ExtendableLogRow}
        containerStyles={{
          mt: '24px',
        }}
        thStyles={{
          p: '13.5px 16px',
          '&:first-of-type': {
            px: '36px',
          },
        }}
        tdStyles={{
          p: '16px',
          '&first-child': {
            px: '24px',
          },
        }}
      />
    </>
  )
}
